import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "about-photo.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout colouredBackground>
      <Wrapper>
        <SEO title="About" />
        <h1>About</h1>
        <StyledImg fluid={data.placeholderImage.childImageSharp.fluid} />
        <p>
          I’m an Edinburgh-based and middleweight designer with a focus on
          illustration and animation. I love working on projects that are
          inspired by real stories and information, crafting it together with
          different styles and colours to make it visual and memorable.
        </p>
        <p>
          I often work on sketchnotes when I attend events and discussions,
          using it as a way to help others capture their thoughts and ideas
          while fine-tuning my own listening skills!
        </p>
        <p>
          After graduating from University of Sunderland in 2017, I began
          working as a graphic designer at Concision where illustration and
          animation became an integral part to many branding and web development
          projects. Also, as head of design, I took the lead on developing the
          design and brand identity for the app, Parent Scheme until March 2020.
        </p>
        <p>
          From then, I freelanced as an illustrator and designer for clients
          such as Noodoll, System Social, and 31ten Consulting. Reach out for a
          chat!
        </p>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

const Wrapper = styled.div`
  padding-top: 4em;
  color: white;
  max-width: 550px;
  margin: 0 auto;

  html {
    background-color: read-only;
  }
  h1 {
    font-weight: bold;
    text-align: center;
    font-size: 2em;
  }
  p > a {
    color: white;
  }
`;

const StyledImg = styled(Img)`
  margin: 2em auto;
  width: 250px;
  border-radius: 125px;
  ${({ theme: { blue } }) => `border: 2px solid ${blue}`};
`;
